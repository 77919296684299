import React, { createContext, useState, useContext } from 'react';

const AdTrayContext = createContext();

export const AdTrayProvider = ({ children }) => {
  const [showAdTray, setShowAdTray] = useState(true);

  return (
    <AdTrayContext.Provider value={{ showAdTray, setShowAdTray }}>
      {children}
    </AdTrayContext.Provider>
  );
};

export const useAdTray = () => useContext(AdTrayContext);