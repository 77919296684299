import axios from 'axios';
import { ENDPOINTS } from './endpoints';

const API = axios.create({ baseURL: `${process.env.REACT_APP_FRONTEND_URL}/platform-analytics/` });
// const API = axios.create({ baseURL: `http://${process.env.REACT_APP_SERVICES_PLATFORM_ANALYTICS_ADDRESS}:${process.env.REACT_APP_SERVICES_PLATFORM_ANALYTICS_PORT}/` });

const getCategoryProductList = (categoryId) => API.get(ENDPOINTS.get_category_product_list_homepage(categoryId));
const getMostLikedProduct = (page, size, categoryId) => API.get(ENDPOINTS.get_most_liked_product(page, size, categoryId));
const getMostReviewedProduct = (page, size, categoryId) => API.get(ENDPOINTS.get_most_reviewd_product(page, size, categoryId));
const getMostViewedProduct = (page, size, categoryId) => API.get(ENDPOINTS.get_most_viewed_product(page, size, categoryId));

export {
  getCategoryProductList,
  getMostLikedProduct,
  getMostReviewedProduct,
  getMostViewedProduct,
};
