import Box from 'ReusableComponents/Box';
import React, { useState } from 'react';
import TopbarM from 'Pages/ProductDetails/Topbar/TopbarM';
import TopBar from 'Pages/ProductDetails/Topbar';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'helpers';
import { Helmet } from 'react-helmet';
import { DESIGN_CONFIG, STRING_CONFIG } from 'utils';
import Input from 'ReusableComponents/Input';
import { deleteUserAccount } from 'api/userRequests';
import { message } from 'antd';
import Footer from 'Pages/Footer';
import Text from 'ReusableComponents/Text';

function DeleteMyAccount() {
  const [email, setEmail] = useState('');
  const smallDevice = useMedia('(max-width:450px)');
  const history = useHistory();

  const deleteAccount = async () => {
    if (!email) {
      message.error('Please enter your email');
      return;
    }
    try {
      const { data } = await deleteUserAccount();
      if (data.success === true) {
        message.success('Account deleted successfully');
        setTimeout(() => {
          history.push('/sign-in');
        }, 2000);
      }
    } catch (error) {
      throw error;
    }
  };

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      gap="1rem"
      justifyContent="center"
      marginTop={smallDevice ? '1rem' : '5rem'}
      padding={smallDevice ? '0 1rem' : '0'}
      w="100%"
    >
      <Box
        b={`1px solid ${DESIGN_CONFIG.color.error.error400}`}
        background={DESIGN_CONFIG.color.error.error50}
        borderRadius="8px"
        padding="1rem"
        w={smallDevice ? '100%' : '30.188rem'}
      >
        <Text
          fontSize={DESIGN_CONFIG.typography.mainCard.webMnCardTitle.fontSize}
          fontWeight={DESIGN_CONFIG.typography.mainCard.webMnCardTitle.fontWeight}
        >
          {STRING_CONFIG.inPage.myAccount.txtDeleteWarningTitle}
        </Text>
        <ul>
          <li style={{ listStyleType: 'disc' }}>{STRING_CONFIG.inPage.myAccount.txtDeleteWarningFirst}</li>
          <li style={{ listStyleType: 'disc' }}>{STRING_CONFIG.inPage.myAccount.txtDeleteWarningSecond}</li>
          <li style={{ listStyleType: 'disc' }}>{STRING_CONFIG.inPage.myAccount.txtDeleteWarningThird}</li>
        </ul>
      </Box>
      <Box
        b={`1px solid ${DESIGN_CONFIG.color.success.success400}`}
        background={DESIGN_CONFIG.color.success.success50}
        borderRadius="8px"
        padding="1rem"
        w={smallDevice ? '100%' : '30.188rem'}
      >
        <Text
          fontSize={DESIGN_CONFIG.typography.mainCard.webMnCardTitle.fontSize}
          fontWeight={DESIGN_CONFIG.typography.mainCard.webMnCardTitle.fontWeight}
        >
          {STRING_CONFIG.inPage.myAccount.txtRecoveryTitle}
        </Text>
        <Text>
          {STRING_CONFIG.inPage.myAccount.txtRecoverySubtext}
        </Text>
      </Box>
      <button
        onClick={deleteAccount}
        type="button"
        style={{
          background: `${DESIGN_CONFIG.color.error.errorMain}`,
          border: 'none',
          borderRadius: '6px',
          color: `${DESIGN_CONFIG.color.neutral.neutral500}`,
          cursor: 'pointer',
          fontSize: `${DESIGN_CONFIG.typography.common.webButton.fontSize}`,
          fontWeight: `${DESIGN_CONFIG.typography.common.webButton.fontWeight}`,
          padding: '1rem 2rem',
          width: `${smallDevice ? '100%' : '30.188rem'}`,
        }}
      >
        {STRING_CONFIG.inPage.myAccount.btnDeleteConfirm}
      </button>
      <Box h="2rem" />
    </Box>
  );
}

export default DeleteMyAccount;
