import { platformSearch } from 'api/platformRequest';
import React, { useState, useEffect } from 'react';
import { MdSearch } from 'react-icons/md';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import './style.css';
import Button from 'ReusableComponents/Button';
import { DESIGN_CONFIG, STRING_CONFIG } from 'utils';
import { storePlatformSearchInputValue } from 'store/platform/actions';
import { useDispatch, useSelector } from 'react-redux';
import { formatNumberAsRepresentation } from 'helpers';
import { useAdTray } from 'contexts/AdTrayContext';

function MSearch() {
  const dispatch = useDispatch();
  const inputValueFromStore = useSelector((state) => state.platform.platformSearchInputValue);
  const history = useHistory();
  const [suggestions, setSuggestions] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);
  const { setShowAdTray } = useAdTray();

  useEffect(() => {
    setShowAdTray(false);
  }, [])

  useEffect(() => {
    const makeAPICall = async () => {
      if (inputValueFromStore.trim().length >= 3) {
        try {
          const { data } = await platformSearch(inputValueFromStore);
          if (data.success === true) {
            setSuggestions(data.products);
          }
        } catch (error) {
          console.error('Error fetching suggestions:', error);
        }
      } else {
        setSuggestions([]);
      }
    };
    makeAPICall();
  }, [inputValueFromStore]);

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      setSelectedSuggestionIndex((prevIndex) => {
        const nextIndex = prevIndex < suggestions.length - 1 ? prevIndex + 1 : 0;
        return nextIndex;
      });
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      setSelectedSuggestionIndex((prevIndex) => {
        const nextIndex = prevIndex > 0 ? prevIndex - 1 : suggestions.length - 1;
        return nextIndex;
      });
    } else if (event.key === 'Enter') {
      event.preventDefault();
      if (selectedSuggestionIndex >= 0 && selectedSuggestionIndex < suggestions.length) {
        const selectedProduct = suggestions[selectedSuggestionIndex];
        history.push(`/product/${selectedProduct.id}`);
      } else if (inputValueFromStore.trim() !== '' && inputValueFromStore.length > 2) {
        dispatch(storePlatformSearchInputValue(inputValueFromStore));
        history.push(`/search/${inputValueFromStore}`);
      }
    }
  };

  useEffect(() => {
    setSelectedSuggestionIndex(-1);
  }, [suggestions]);

  return (
    <>
      <div style={{
        display: 'flex',
        gap: '1rem',
        position: 'relative',
        padding: '.75rem 1.5rem',
      }}
      >
        <Button
          alignItems="center"
          aria-label="back"
          border="none"
          display="flex"
          onClick={() => history.goBack()}
          padding={0}
        >
          <img
            alt=""
            height={24}
            src="/icons/searchSmallBack.svg"
            width={24}
          />
        </Button>
        <div className={`search-input-small ${isFocused ? 'focused' : null}`} role="search">
          <input
            aria-autocomplete="list"
            aria-label="Search Input"
            aria-owns={suggestions?.length > 0 ? 'suggestions-list' : null}
            onBlur={() => setIsFocused(false)}
            onChange={(e) => { dispatch(storePlatformSearchInputValue(e.target.value)); }}
            onFocus={() => setIsFocused(true)}
            onKeyDown={handleKeyDown}
            placeholder={STRING_CONFIG.inPage.common.lnkNavBarSearch}
            type="text"
            value={inputValueFromStore}
          />
          <MdSearch
            color={isFocused ? DESIGN_CONFIG.color.primary.primaryMain : DESIGN_CONFIG.color.text.text200}
            size={24}
          />
        </div>
      </div>
      {isFocused && inputValueFromStore && suggestions?.length > 0 && (
        <ul
          className="suggestions-small"
          id="suggestions-list"
          role="listbox"
        >
          {suggestions.slice(0, 6).map((result, index) => (
            <li
              aria-selected={selectedSuggestionIndex === index ? 'true' : 'false'}
              className={`list-item ${selectedSuggestionIndex === index ? 'selected' : null}`}
              key={result.id}
              onMouseDown={(event) => {
                event.preventDefault();
                history.push(`/product/${result.id}`);
              }}
              role="option"
            >
              <img alt="" className="image" src={result.image} />
              <div className="product-details">
                <p style={{
                  color: `${DESIGN_CONFIG.color.text.text500}`,
                  fontSize: '.875rem',
                  fontWeight: 400,
                  margin: 0,
                }}
                >
                  {result.model}
                  {' '}
                  {result.variant}
                </p>
                <p style={{
                  color: `${DESIGN_CONFIG.color.text.text100}`,
                  fontSize: '.75rem',
                  fontWeight: 400,
                  margin: 0,
                }}
                >
                  {result.brand}
                </p>
                <div style={{
                  alignItems: 'center',
                  display: 'flex',
                  gap: '.5rem',
                }}
                >
                  <div style={{
                    background: `${DESIGN_CONFIG.color.primary.primaryMain}`,
                    display: 'flex',
                    gap: '.25rem',
                    padding: '.25rem',
                  }}
                  >
                    <img
                      alt=""
                      height={20}
                      src="/assets/whiteStartSearchModal.svg"
                      width={12}
                    />
                    <p style={{
                      color: `${DESIGN_CONFIG.color.neutral.neutral500}`,
                      fontSize: '.75rem',
                      fontWeight: 500,
                      margin: 0,
                    }}
                    >
                      {result.value}
                    </p>
                  </div>
                  <p style={{
                    color: `${DESIGN_CONFIG.color.text.text100}`,
                    fontSize: '.75rem',
                    fontWeight: 400,
                    margin: 0,
                  }}
                  >
                    •
                    {formatNumberAsRepresentation(result.total_review)}
                    {' '}
                    {STRING_CONFIG.inPage.product.txtReviewTitle}
                  </p>
                </div>

              </div>
            </li>
          ))}
        </ul>
      )}
    </>
  );
}

export default MSearch;
