import styled from 'styled-components';

export const RatingBarStyled = styled.div`
  line-height: ${({ lineHeight }) => lineHeight};
  height: ${({ h }) => h};
  width: ${({ w }) => w};
  border-radius: ${({ borderRadius }) => borderRadius};
  background: ${({ background }) => background};
`;

export const RatingBarDivStyled = styled.div`
  height: ${({ h }) => h};
  width: ${({ w }) => w};
  margin-top: ${({ marginTop }) => marginTop};
  border-radius: ${({ borderRadius }) => borderRadius};
  background: ${({ background }) => background};
`;
