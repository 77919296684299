import { platformSearch } from 'api/platformRequest';
import { formatNumberAsRepresentation } from 'helpers';
import React, { useState, useEffect } from 'react';
import { MdSearch } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Button from 'ReusableComponents/Button';
import { clearPlatformSearchInputValue, storePlatformSearchInputValue } from 'store/platform/actions';
import { DESIGN_CONFIG, STRING_CONFIG } from 'utils';

function SearchInput() {
  const dispatch = useDispatch();
  const inputValueFromStore = useSelector((state) => state.platform.platformSearchInputValue);
  const history = useHistory();
  const [suggestions, setSuggestions] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);

  useEffect(() => {
    const makeApiCall = async () => {
      if (inputValueFromStore.trim().length >= 3) {
        try {
          const { data } = await platformSearch(inputValueFromStore);
          if (data.success === true) {
            setSuggestions(data.products);
          }
        } catch (error) {
          console.error('Error fetching suggestions:', error);
        }
      } else {
        setSuggestions([]);
      }
    };
    makeApiCall();
  }, [inputValueFromStore]);

  const handleKeyDown = (event) => {
    const totalVisibleSuggestions = Math.min(5, suggestions.length);
    const totalItems = totalVisibleSuggestions + 1;
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      setSelectedSuggestionIndex((prevIndex) => (prevIndex + 1) % totalItems);
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      setSelectedSuggestionIndex((prevIndex) => (prevIndex - 1 + totalItems) % totalItems);
    } else if (event.key === 'Enter') {
      event.preventDefault();
      if (selectedSuggestionIndex >= 0 && selectedSuggestionIndex < totalVisibleSuggestions) {
        const selectedProduct = suggestions[selectedSuggestionIndex];
        history.push(`/product/${selectedProduct.id}`);
      } else if (selectedSuggestionIndex === totalVisibleSuggestions) {
        history.push(`/search/${inputValueFromStore}`);
      } else if (inputValueFromStore.trim()) {
        history.push(`/search/${inputValueFromStore}`);
      }
    }
  };

  useEffect(() => {
    setSelectedSuggestionIndex(-1);
  }, [suggestions]);

  useEffect(() => {
    if (!history.location.pathname.includes('/search')) {
      dispatch(clearPlatformSearchInputValue(''));
    }
  }, [history.location]);

  return (
    <div style={{ position: 'relative' }}>
      <div className={`search-input ${isFocused ? 'focused' : null}`} role="search">
        <input
          aria-autocomplete="list"
          aria-label="Search Input"
          aria-owns={suggestions?.length > 0 ? 'suggestions-list' : null}
          onBlur={() => setIsFocused(false)}
          onChange={(e) => { dispatch(storePlatformSearchInputValue(e.target.value)); }}
          onFocus={() => setIsFocused(true)}
          onKeyDown={handleKeyDown}
          placeholder={STRING_CONFIG.inPage.common.plHNavBarSearch}
          type="text"
          value={`${inputValueFromStore}`}
        />
        <MdSearch
          color={isFocused ? DESIGN_CONFIG.color.primary.primaryMain : DESIGN_CONFIG.color.text.text200}
          size={24}
        />
      </div>
      {isFocused && inputValueFromStore && suggestions?.length > 0 && (
        <ul
          className="suggestions"
          id="suggestions-list"
          role="listbox"
        >
          {suggestions.slice(0, 5).map((result, index) => (
            <li
              aria-selected={selectedSuggestionIndex === index ? 'true' : 'false'}
              className={`list-item ${selectedSuggestionIndex === index ? 'selected' : null}`}
              key={result.id}
              onMouseDown={(event) => {
                event.preventDefault();
                history.push(`/product/${result.id}`);
              }}
              role="option"
            >
              <img alt="" className="image" src={result.image} />
              <div className="product-details">
                <p style={{
                  color: `${DESIGN_CONFIG.color.text.text500}`,
                  fontSize: `${DESIGN_CONFIG.typography.mainCard.webMnCardTitle.fontSize}`,
                  fontWeight: `${DESIGN_CONFIG.typography.mainCard.webMnCardTitle.fontWeight}`,
                  margin: 0,
                }}
                >
                  {result.model}
                  {' '}
                  {result.variant}
                </p>
                <p style={{
                  color: `${DESIGN_CONFIG.color.text.text100}`,
                  fontSize: `${DESIGN_CONFIG.typography.mainCard.webMnCardSubtitle.fontSize}`,
                  fontWeight: `${DESIGN_CONFIG.typography.mainCard.webMnCardSubtitle.fontWeight}`,
                  margin: 0,
                }}
                >
                  {result.brand}
                </p>
                <div style={{
                  alignItems: 'center',
                  display: 'flex',
                  gap: '.5rem',
                }}
                >
                  <div style={{
                    background: `${DESIGN_CONFIG.color.primary.primaryMain}`,
                    display: 'flex',
                    gap: '.25rem',
                    padding: '.25rem',
                  }}
                  >
                    <img
                      alt=""
                      height={20}
                      src="/assets/whiteStartSearchModal.svg"
                      width={12}
                    />
                    <p style={{
                      color: `${DESIGN_CONFIG.color.neutral.neutral500}`,
                      fontSize: `${DESIGN_CONFIG.typography.common.webTag.fontSize}`,
                      fontWeight: `${DESIGN_CONFIG.typography.common.webTag.fontWeight}`,
                      margin: 0,
                    }}
                    >
                      {result.value}
                    </p>
                  </div>
                  <p style={{
                    color: `${DESIGN_CONFIG.color.text.text100}`,
                    fontSize: `${DESIGN_CONFIG.typography.mainCard.webMnCardSubtitle.fontSize}`,
                    fontWeight: `${DESIGN_CONFIG.typography.mainCard.webMnCardSubtitle.fontWeight}`,
                    margin: 0,
                  }}
                  >
                    •
                    {formatNumberAsRepresentation(result.total_reviews)}
                  </p>
                </div>
              </div>
            </li>
          ))}
          {suggestions.length > 5 && (
            <Button
              aria-selected={selectedSuggestionIndex === suggestions.length ? 'true' : 'false'}
              background={DESIGN_CONFIG.color.neutral.neutral500}
              border={`1px solid ${DESIGN_CONFIG.color.neutral.neutral100}`}
              borderRadius="4px"
              cursor="pointer"
              fontSize={DESIGN_CONFIG.typography.common.webButton.fontSize}
              fontWeight={DESIGN_CONFIG.typography.common.webButton.fontWeight}
              marginTop=".5rem"
              onClick={() => history.push(`/search/${inputValueFromStore}`)}
              padding="1rem"
              textColor={DESIGN_CONFIG.color.text.text500}
              w="100%"
            >
              {STRING_CONFIG.inPage.common.btnViewAllSearchResults}
            </Button>
          )}
        </ul>
      )}
    </div>
  );
}

export default SearchInput;
