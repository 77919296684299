import React from 'react';
import { useMedia } from 'helpers';
import { DESIGN_CONFIG } from 'utils';
import { RatingBarStyled, RatingBarDivStyled } from './styles';

function RatingBar({ rating, ...props }) {
  const [main, setMain] = React.useState(DESIGN_CONFIG.color.primary.primaryMain);
  const [width, setWidth] = React.useState('100%');
  const [background, setBackground] = React.useState(DESIGN_CONFIG.color.primary.primary50);
  const smallDevice = useMedia('(max-width:450px)');

  const getPercentValue = (num) => {
    const x = (num / 5) * 100;
    return x;
  };
  const changeSlidersColor = () => {
    if (rating >= 0 && rating < 2) {
      setMain(DESIGN_CONFIG.color.error.errorMain);
      setBackground(DESIGN_CONFIG.color.error.error50);
      setWidth(`${getPercentValue(rating)}%`);
    } else if (rating >= 2 && rating < 3) {
      setMain(DESIGN_CONFIG.color.warning.warningMain);
      setBackground(DESIGN_CONFIG.color.warning.warning50);
      setWidth(`${getPercentValue(rating)}%`);
    } else if (rating >= 3 && rating < 4) {
      setMain(DESIGN_CONFIG.color.success.successMain);
      setBackground(DESIGN_CONFIG.color.success.success50);
      setWidth(`${getPercentValue(rating)}%`);
    } else if (rating >= 4 && rating < 5) {
      setMain(DESIGN_CONFIG.color.primary.primaryMain);
      setBackground(DESIGN_CONFIG.color.primary.primary50);
      setWidth(`${getPercentValue(rating)}%`);
    } else if (rating === 5) {
      setMain(DESIGN_CONFIG.color.gradient.highlight);
      setBackground(DESIGN_CONFIG.color.gradient.highlight);
      setWidth(`${getPercentValue(rating)}%`);
    }
  };
  React.useEffect(() => {
    changeSlidersColor();
  }, [rating]);

  return (
    <RatingBarDivStyled
      background={background}
      borderRadius="4px"
      h={smallDevice ? '2px' : '4px'}
      marginTop={props.marginTop}
      w={props.width ? props.width : '192px'}
    >
      <RatingBarStyled
        borderRadius="6px"
        background={main}
        h={smallDevice ? '2px' : '4px'}
        w={width}
      />
    </RatingBarDivStyled>
  );
}

export default RatingBar;
